const semaphores = {
  snackbar: false
};

let lastTimeOut = null;

export default {
  namespaced: true,
  state: {
    snackbarMessage: "",
    snackbarMessageColor: "",
    snackbar: false,
    snackbarTimeout: 5000,
    snackbarColor: ""
  },
  mutations: {
    SET_SNACK_BAR(state, { msg, msgColor, color, timeout }) {
      state.snackbarMessage = msg;
      state.snackbarColor = color;
      state.snackbarMessageColor = msgColor;
      state.snackbarTimeout = timeout;
      state.snackbar = true;
    },
    HIDE_SNACK_BAR(state) {
      state.snackbar = false;
    }
  },
  getters: {
    snackbarColor: state => state.snackbarColor,
    snackbarMessage: state => state.snackbarMessage,
    snackbarMessageColor: state => state.snackbarMessageColor,
    snackbar: state => state.snackbar,
    snackbarTimeout: state => state.snackbarTimeout
  },
  actions: {
    showSnackBar({ commit, state }, { msg, msgColor, color = "", timeout }) {
      if (!semaphores.snackbar) {
        commit("SET_SNACK_BAR", { msg, msgColor, color, timeout });
        semaphores.snackbar = true;
        lastTimeOut = (function() {
          commit("HIDE_SNACK_BAR");
          semaphores.snackbar = false;
        }, state.snackbarTimeout);
      } else {
        if (lastTimeOut) clearTimeout(lastTimeOut);
        commit("HIDE_SNACK_BAR");
        commit("SET_SNACK_BAR", { msg, msgColor, color, timeout });
        lastTimeOut = setTimeout(function() {
          commit("HIDE_SNACK_BAR");
          semaphores.snackbar = false;
        }, state.snackbarTimeout);
      }
    }
  }
};
