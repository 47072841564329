import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./core/services/store";
import ApiService from "./core/services/api.service";
import { VERIFY_AUTH } from "./core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
Vue.config.productionTip = false;
import * as VueGoogleMaps from "vue2-google-maps";
import VueSignalR from "@latelier/vue-signalr";
import Vuelidate from 'vuelidate'

import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

// Vue 3rd party plugins
import i18n from "./core/plugins/vue-i18n";
import vuetify from "./core/plugins/vuetify";
import "./core/plugins/portal-vue";
import "./core/plugins/bootstrap-vue";
import "./core/plugins/perfect-scrollbar";
import "./core/plugins/highlight-js";
import "./core/plugins/inline-svg";
import "./core/plugins/apexcharts";
import "./core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import * as helpers from "./helpers";
import mDatePicker from 'vue-multi-date-picker'
// import axios from "axios";
// import JwtService from "@/core/services/jwt.service";

// API service init
ApiService.init();
ApiService.setHeader();
// const token = localStorage.getItem('token')
// if (token) {
//   Vue.prototype.$http.defaults.headers.common['Authorization'] = token
// }

// Remove this to disable mock API
// MockService.init();

// This code to ensure we have auth user
router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});
Vue.use(VueSignalR, `${Vue.axios.defaults.baseURL }NotificationHub`);
Vue.use(mDatePicker)
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyC_p6eIk5pVu36eDncibueSPtjKvjp8Z5k",
    libraries: "places"
  },
  installComponents: true
});
window.hel = helpers;
Vue.use(vuetify, { rtl: true });
Vue.use(Vuelidate);

Vue.prototype.ApiService = ApiService;
Vue.prototype.hel = helpers;
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
