import store from "./core/services/store";

export const log = () => {
  //  console.log(data)
};

export const showSnackMsg = async function(
  msg,
  color = "",
  msgColor = "",
  timeout = 5000
) {

  await store.dispatch("snackMsg/showSnackBar", {
    msg,
    msgColor,
    color,
    timeout
  });
};
