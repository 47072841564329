import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import Vue from "vue";
// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
export const GET_USER = "getUser";
export const LOGOUTTIMER = "logoutTimer";
export const VERIFY_CODE = "verifyCode";
export const GENERATE_CODE = "generateCode";
export const FORGET_PASSWORD = "forgetPassword";
export const RESET_PASSWORD = "resetPassword";
export const VERIFY_FORGET_CODE = "verfyForgetCode";
// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";

const state = {
  errors: [],
  user: {},
  id: "",
  temp: {},
  email: "",
  code: "",
  token: localStorage.getItem("token") || "",
  isAuthenticated: JwtService.authUser()
  //!!JwtService.getToken()JwtService.authUser()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};
const actions = {
  [FORGET_PASSWORD](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("auth/ForgotPassword?email=" + credentials.e_mail)
        .then(({ data }) => {
          context.state.email = credentials.e_mail;
          // store remember me
          window.localStorage.setItem("rm", "false");
          context.dispatch(GENERATE_CODE);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_ERROR, "فشلت العملية, تأكد من الإيميل!");
        });
    });
  },
  [RESET_PASSWORD](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("auth/ResetPassword?email=" + context.state.email, {
        newPassword: credentials.password,
        confirmPassword: credentials.confirmPassword,
        code: context.state.code
      })
        .then(res => {
          window.localStorage.setItem("id", res.data.user.id);
          const now = new Date();
          const expirationDate = new Date(now.getTime() + 3600000);
          window.localStorage.setItem("experesIn", expirationDate);
          context.commit(SET_AUTH, res.data);
          context.state.code = "";
          context.state.email = "";
          resolve();
        })
        .catch(() => {
          context.commit(SET_ERROR, "فشلت العملية, إعدالمحاولة");
        });
    });
  },
  [LOGIN](context, credentials) {
    // const header = `Authorization: Bearer ${token}`;
    return new Promise(resolve => {
      ApiService.post("Auth/Login", credentials)
        .then(({ data }) => {
         

          
          context.state.temp = data.user;
          context.state.id = data.user.id;
          // const token=data.user.token
          Vue.prototype.$http.defaults.headers.common["Authorization"] =
            "Bearer " + data.token;
          localStorage.setItem("token", JSON.stringify(data.token));
          // const token = localStorage.getItem('token')
          // if (token) {
          //   Vue.prototype.$http.defaults.headers.common['Authorization'] =`bearer ${token}`
          // }

          // store remember me
          window.localStorage.setItem("rm", credentials.rememberMe);
          context.dispatch(GENERATE_CODE);
          resolve(data);
        })
        .catch(() => {
          context.commit(
            SET_ERROR,
            "فشلت العملية, تأكد من الإيميل أو كلمة السر"
          );
        });
    });
  },
  [GENERATE_CODE](context) {

    return new Promise(resolve => {
      ApiService.get(`Auth/generatecode?id=` + context.state.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [VERIFY_FORGET_CODE](context, credentials) {
    return new Promise(resolve => {
      ApiService.post(
        "auth/Verifyemail?email=" + context.state.email + "&code=" + credentials
      )
        .then(({ data }) => {
          context.state.code = credentials;
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_ERROR, "فشلت العملية, تأكد من الكود المرسل");
        });
    });
  },
  [VERIFY_CODE](context, credentials) {
    return new Promise(resolve => {
      ApiService.post(
        "Auth/Verify?id=" + context.state.id + "&code=" + credentials
      )
        .then(({ data }) => {
          window.localStorage.setItem("id", context.state.id);
      
          
          const now = new Date();
          const expirationDate = new Date(now.getTime() + 3600000);
          window.localStorage.setItem("experesIn", expirationDate);
          //  context.dispatch(GET_USER)
          context.commit(SET_AUTH, context.state.temp);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_ERROR, "فشلت العملية, تأكد من الكود المرسل");
        });
       
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [GET_USER](context) {
    let info = context.state.info
      ? context.state.info.user.id
      : context.state.id
      ? JSON.parse(window.localStorage.getItem("id"))
      : "";
    ApiService.get("Auth/GetUser?id=" + info)
      .then(({ data }) => {
        context.commit(SET_AUTH, data);
      })
      .catch(() => {
        // context.commit(SET_ERROR, response.data.errors);
      });
  },
  [VERIFY_AUTH](context) {
    const rememberMe = window.localStorage.getItem("rm")
      ? window.localStorage.getItem("rm")
      : "false";

    if (rememberMe == "false") {
      const expired = window.localStorage.getItem("experesIn");
      const now = new Date();
      if (now >= expired) {
        context.commit(PURGE_AUTH);
      } else {
        context.dispatch(GET_USER);
      }
    } else if (rememberMe == "true") {
      context.dispatch(GET_USER);
    }
  }
};
const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, data) {
  
    let userAuth=JSON.parse(localStorage.getItem("authUser"))

    if(data){
      window.localStorage.setItem(
        "id",
        JSON.stringify(data.user ? data.user.id : data.id)
      );
     
      window.localStorage.setItem(
        "authUser",
        JSON.stringify(data.user ? data.user : data)
      );
      window.localStorage.setItem("roles", data.roles ? data.roles : "");
    
    }
    else{
      window.localStorage.setItem(
        "id",
        JSON.stringify(userAuth.id)
      );
      window.localStorage.setItem(
        "authUser",
        JSON.stringify(userAuth)
      );
    }
  
    state.isAuthenticated = true;
    state.user = data.user ? data.user : data;
    state.roles = data.roles ? data.roles : "";
    state.errors = {};
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.roles = {};
    state.errors = {};
    window.localStorage.clear();
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
