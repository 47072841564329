import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
// import JwtService from "@/core/services/jwt.service";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    // const token="Dd3F0ME5EWlluMXZHZE9rb0ZsS01NcVUrRFBZOXA2Nm5sS2xjQkRSZnVqemYva3hEbWE1NktSQU95eFNIUT09IiwiZXhwIjoxNjU4NTIxOTM1LCJpc3MiOiJZYW1hbWFBcGlBdXRoZW50aWNhdGlvblNlcnZlciIsImF1ZCI6IllhbWFtYUFwaVNlcnZpY2VQb3N0bWFuQ2xpZW50In0.RejZlfHek_VsYQJ_10CUdvqGCbvhpASxILvUw9PQb1k"
// "https://salestools.yamamacement.com/";
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = "https://salestools.yamamacement.com:443/"; //http://yc-sales-dev.developmentdemos.com/      https://salestools.yamamacement.com";
    Vue.axios.defaults.withCredentials = true;
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Content-Type, Authorization, X-Requested-With';
    axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'DELETE, POST, GET, OPTIONS';
    
 
    const token = JSON.parse(localStorage.getItem("token"));
    Vue.prototype.$http.defaults.headers.common["Authorization"] =
      "Bearer " + token;

    // if (token) {
    //   Vue.prototype.$http.defaults.headers.common['Authorization'] =`bearer ${token}`;
  },
  // Vue.axios.defaults.headers.common[
  //   "Authorization"
  // ] =`bearer ${token}`;

  //yc-sales-dev.developmentdemos.com/http://yc.developmentdemos.com/"

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    // Vue.axios.defaults.headers.common[
    //   "Authorization"
    // ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
    
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  /*  get(resource, slug = "") {
    if(slug==""){
      return Vue.axios.get(`${resource}`).catch(error => {
    
        throw new Error(`[KT] ApiService ${error}`);
      });
    }else{
      return Vue.axios.get(`${resource}/${slug}`).catch(error => {
       
        throw new Error(`[KT] ApiService ${error}`);
      });
    }
  },*/
  get(resource) {
    return Vue.axios.get(`${resource}`).catch(error => {
    
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
     
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;
