<template>
  <div data-app>
    <v-app>
      <router-view></router-view>
      <v-snackbar
        :color="snackbarColor"
        :value="snackbar"
        :timeout="snackbarTimeout"
        top
        class="text-center"
      >
        <strong class="text-center">
          <template v-if="snackbarMessage instanceof Array">
            <template v-for="(msg, i) in snackbarMessage">
              <template v-if="msg.link">
                <router-link
                  v-text="msg.text"
                  :to="msg.link"
                  :key="i"
                ></router-link>
                &nbsp;
              </template>
              <template v-else>
                <span :style="`color: ${msg.color}`" :key="i">
                  {{ msg.text }} </span
                >&nbsp;
              </template>
            </template>
          </template>
          <template v-else>
            <span :style="`color: ${snackbarMessageColor}`">
              {{ snackbarMessage }}
            </span>
          </template>
        </strong>
      </v-snackbar>
    </v-app>
  </div>
</template>

<style lang="scss">
.v-data-table .v-data-table__wrapper table thead tr th {
  font-size: 14px !important;
}
.v-data-table .v-data-table__wrapper table tbody tr th,
.v-data-table .v-data-table__wrapper table tfoot tr th {
  font-size: 13px !important;
}
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

// Main demo style scss
//@import "assets/sass/style.vue";

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
@import "assets/css/style.vue.rtl";
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { mapGetters } from "vuex";

export default {
  name: "Yamama",
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  },
  computed: {
    ...mapGetters("snackMsg", [
      "snackbarMessage",
      "snackbarMessageColor",
      "snackbarColor",
      "snackbar",
      "snackbarTimeout"
    ])
  },
  created() {
    this.$vuetify.rtl = true;
    this.$socket.start({
      log: false
    });
    
     
     
  }
};
</script>
