import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  // mode:history,
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/components/home/index.vue")
          //  component: () => import("@/view/pages/Dashboard.vue")
        },

        {
          path: "/notifications",
          name: "notifications",
          component: () => import("@/view/components/shared/notifications.vue")
        },
        {
          path: "/my-profile",
          name: "my-profile",
          component: () => import("@/view/components/users/Profile.vue")
        },
        {
          path: "/users",
          name: "users",

          component: () => import("@/view/components/users/index.vue")
        },
        {
          path: "/targets",
          name: "targets",
          component: () => import("@/view/components/reports/targets.vue")
        },
        {
          path: "/achievements",
          name: "achievements",
          component: () => import("@/view/components/reports/achievements.vue")
        },
        {
          path: "/messages",
          name: "messages",
          component: () => import("@/view/components/messages/index.vue")
        },
       
        {
          path: "/add-questionnaire",
          name: "add_questionnaire",
          component: () =>
            import("@/view/components/questionnaires/addQuestionnaire.vue")
        },
        {
          path: "/show-questionnaire/:questionnaireId/replay/:replyId",
          name: "show_questionnaire",
          component: () =>
            import("@/view/components/questionnaires/showQuestionnaire.vue")
        },
        {
          path: "/fill-questionnaire",
          name: "fill_questionnaire",
          component: () =>
            import("@/view/components/questionnaires/fillQuestionnaire.vue")
        },
        {
          path: "/questionnaires",
          name: "questionnaires",
          component: () =>
            import("@/view/components/questionnaires/allQuestionnaires.vue")
        },
        {
          path: "/show-user/:userId",
          name: "show-user",
          component: () => import("@/view/components/users/Profile.vue")
        },
        {
          path: "/add-user",
          name: "add-user",
          component: () => import("@/view/components/users/AddUser.vue")
        },
        {
          path: "/edit-user/:userId",
          name: "edit-user",
          component: () => import("@/view/components/users/EditUser.vue")
        },
        {
          path: "/edit-password",
          name: "edit-password",
          component: () => import("@/view/components/users/changePassword.vue")
        },
        {
          path: "/edit-password/:Id",
          name: "edit-user-password",
          component: () => import("@/view/components/users/changePassword.vue")
        },
        {
          path: "/tasks",
          name: "tasks",
          component: () => import("@/view/components/tasks/index.vue")
        },
        {
          path: "/tasks/:type",
          name: "all-tasks",
          component: () => import("@/view/components/tasks/allTask.vue")
        },
        {
          path: "/chatting/:taskId",
          name: "chatting",
          component: () => import("@/view/components/visits/chatComponent.vue")
        },
        {
          path: "/visits/:type",
          name: "all-visit",
          component: () => import("@/view/components/visits/AllVisits.vue")
        },
        {
          path: "add-task",
          name: "add-task",
          component: () => import("@/view/components/tasks/addTask.vue")
        },
        {
          path: "/edit-task/:taskId",
          name: "edit-task",
          component: () => import("@/view/components/tasks/EditTask.vue")
        },
        {
          path: "/my-task",
          name: "my-task",
          component: () => import("@/view/components/tasks/myTasks.vue")
        },
        {
          path: "/show-task/:taskId",
          name: "show-task",
          component: () => import("@/view/components/tasks/ShowTask.vue")
        },
        {
          path: "/visits",
          name: "visits",
          component: () => import("@/view/components/visits/index.vue")
        },
        {
          path: "/show-visit/:visitId",
          name: "show-visit",
          component: () => import("@/view/components/visits/showVisit.vue")
        },
        {
          path: "/edit-visit/:visitId",
          name: "edit-visit",
          component: () => import("@/view/components/visits/editVisit.vue")
        },
        // {
        //   path: "/annual-plan",
        //   name: "plan",
        //   component: () => import("@/view/components/oracle-report/annual-plan.vue")
        // },
        {
          path: "/predictive",
          name: "predictive",
          component: () => import("@/view/components/oracle-report/predictive-data.vue")
        },
        // {
        //   path: "/year-sales",
        //   name: "year-sales",
        //   component: () => import("@/view/components/sales/year-sales.
        // },
        // {
        //   path: "/month-sales",
        //   name: "month-sales",
        //   component: () => import("@/view/components/sales/month-sales.vue")
        // },
         {
          path: "/year-report",
          name: "year-report",
          component: () => import("@/view/components/oracle-report/year-report.vue")
        },
          {
          path: "/market-segmentation",
          name: "market-segmentation",
          component: () => import("@/view/components/oracle-report/marketsegmentation.vue")
        },
        {
          path: "/annual-plan",
          name: "annual-plan",
          component: () => import("@/view/components/oracle-report/annual-plan.vue")
        },
        {
          path: "/customer-activity",
          name: "customer-activity",
          component: () => import("@/view/components/oracle-report/customer-activity.vue")
        },
        {
          path: "/marketdetails",
          name: "marketdetails",
          component: () => import("@/view/components/oracle-report/marketdetails.vue")
        },
        {
          path: "/customer-profile",
          name: "customer-profile",
          component: () => import("@/view/components/oracle-report/customer-profile.vue")
        },
        {
          path: "/sales-by-area",
          name: "sales-by-area",
          component: () => import("@/view/components/oracle-report/sales-area.vue")
        },
        {
          path: "/sales-area",
          name: "sales-area",
          component: () => import("@/view/components/oracle-report/sales-area.vue")
        },
        {
          path: "/customer-daily-target",
          name: "customer-daily-target",
          component: () => import("@/view/components/oracle-report/customer-daily-target.vue")
        },
        {
          path: "/create-visit-report/:visitId",
          name: "create-visit-report",
          component: () => import("@/view/components/visits/visitReport.vue")
        },
        
       
        {
          path: "/my-visits",
          name: "my-visits",
          component: () => import("@/view/components/visits/myVisits.vue")
        },
        {
          path: "/visit-reports",
          name: "visit-reports",
          component: () => import("@/view/components/visits/visitReports.vue")
        },
        {
          path: "/externals",
          name: "externals",
          component: () => import("@/view/components/externals/index.vue")
        },
        {
          path: "/add-external",
          name: "add-external",
          component: () => import("@/view/components/externals/addExternal.vue")
        },
        {
          path: "/edit-external/:externalId",
          name: "edit-external",
          component: () =>
            import("@/view/components/externals/editExternal.vue")
        },
        {
          path: "/show-external/:externalId",
          name: "show-external",
          component: () =>
            import("@/view/components/externals/showExternal.vue")
        },
        {
          path: "/show-transporter/:externalId",
          name: "show-transporter",
          component: () =>
            import("@/view/components/externals/showExternal.vue")
        },
        {
          path: "/show-project/:externalId",
          name: "show-project",
          component: () =>
            import("@/view/components/externals/showExternal.vue")
        },
        {
          path: "/external-visits/:externalId",
          name: "external-visits",
          component: () =>
            import("@/view/components/externals/visitExternal.vue")
        },
        {
          path: "/external-visits/:externalId/visit/:visitId",
          name: "external-visit",
          component: () =>
            import("@/view/components/externals/visitDetails.vue")
        },
        {
          path: "/edit-project/:externalId",
          name: "edit-project",
          component: () =>
            import("@/view/components/externals/editExternal.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login")
        },
        {
          name: "forget_password",
          path: "/forget-password",
          component: () => import("@/view/pages/auth/forgetPassword")
        },
        {
          name: "verifyForgetCode",
          path: "/verify-forget-code",
          component: () => import("@/view/pages/auth/verifyCode")
        },
        {
          name: "verifyLoginCode",
          path: "/verify-login-code",
          component: () => import("@/view/pages/auth/verifyCode")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
