const ID_TOKEN_KEY = "id_token";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const authUser = () => {
  const idUser = window.localStorage.getItem("id");
  if (!idUser) {
    return false;
  } else {
    const expired = window.localStorage.getItem("experesIn");
    const now = new Date();
    if (now >= expired) {
      return false;
    } else {
      return true;
    }
  }
};

export default { getToken, saveToken, destroyToken, authUser };
